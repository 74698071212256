import { call, takeLatest, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import _ from 'underscore';

import defaultJson from 'helpers/defaultJson';

import {
  getStatesAction,
  getPlayersAction,
  setPlayerActions,
  showcasePaymentAction,
  setShowPaymentModal,
  submitAction,
  updateBasicStatsAction
} from 'state/actions/showcase';
import { GET_STATES, GET_PLAYERS, SET_PLAYER, SUBMIT, UPDATE_BASIC_STATS, GET_DOCUMENT, SHOWCASE_PAYMENT } from 'state/types';
import { form, batch_cb } from 'utils/hooks/form';
import { API_BASE_PATH, FORM } from 'config';
import { deFormatData, copy, formatData } from 'helpers/common';
import API from 'api';
import { appLoadingAction } from 'state/actions/app';

function* getStates() {
  try {
    const states = yield call(API.getStates);
    yield put(getStatesAction.FULLFILLED(states));
  } catch (error) {
    toast.error(error.details);
    yield put(getStatesAction.REJECTED());
  }
}

function* getPlayers(action) {
  const allShowcases = yield select(state => state.showcase.allShowcases.results);
  try {
    yield put(appLoadingAction.STARTED());
    const selectedShowcase = _.find(allShowcases, { id: action.payload });
    const players = yield call(API.getPlayers, action.payload);
    const sortedPlayers = _.sortBy(players.options, '_lastname');
    yield put(getPlayersAction.FULLFILLED({ players: sortedPlayers, selectedShowcase }));
    yield put(appLoadingAction.FULLFILLED());
  } catch (error) {
    toast.error(error.details);
    yield put(getPlayersAction.REJECTED());
    yield put(appLoadingAction.REJECTED());
  }
}

function* showcasePayment(action) {
  const selectedShowcase = yield select(state => state.showcase.selectedShowcase);
  try {
    const { showcase_id, total_amount, paypal } = action.payload;
    yield call(API.showcasePayment, { total_amount, showcase_id, paypal });
    yield put(showcasePaymentAction.FULLFILLED({ ...selectedShowcase, is_paid: true }));
    yield put(setShowPaymentModal(false));
  } catch (error) {
    toast.error(error.details);
    yield put(getPlayersAction.REJECTED());
  }
}

function* setPlayer(action) {
  const allPlayers = yield select(state => state.showcase.players);
  try {
    yield put(setPlayerActions.FULLFILLED(null));
    const data = find(allPlayers, player => player.id === action.payload);
    let playerData = { ...data };
    if (!isEmpty(get(data, 'player_data.json_data', {}))) {
      playerData = { ...get(data, 'player_data.json_data', {}) };
    }
    const formData = copy(deFormatData(copy(playerData)));
    const player = _.find(allPlayers, { id: action.payload });

    /* Update paragraph when seletc a new player Start */
    const payload = { data: { ...playerData } };
    const para = yield call(API.getParagraph, payload);
    form(FORM.SHOWCASE).setValue('paragraph', para.paragraph);
    const updated_player = copy(player);
    updated_player.player_data.paragraph = para.paragraph;
    updated_player.player_data.paragraph2 = para.paragraph2;
    yield put(setPlayerActions.FULLFILLED(updated_player));
    /* Update paragraph when seletc a new player End */

    form(FORM.SHOWCASE).reset(defaultJson);
    batch_cb(() => {
      form(FORM.SHOWCASE).setValues(formData);
      form(FORM.BASIC_STATS).setValues(formData);
    });
  } catch (error) {
    toast.error(error.details);
    yield put(setPlayerActions.REJECTED());
  }
}

function* submit() {
  const current_player = yield select(state => state.showcase.current_player);
  const allPlayers = yield select(state => state.showcase.players);
  const selectedShowcase = yield select(state => state.showcase.selectedShowcase);
  try {
    const values = form(FORM.SHOWCASE).getValues();
    const formData = copy(formatData(values));
    const payload = { data: { ...current_player, ...formData } };
    const data = yield call(API.getParagraph, payload);
    form(FORM.SHOWCASE).setValue('paragraph', data.paragraph);
    const player = copy(current_player);

    /* Update paragraph and current player data */
    player.player_data.json_data = data.player_data;
    player.player_data.paragraph = data.paragraph;
    player.player_data.paragraph2 = data.paragraph2;
    yield put(setPlayerActions.FULLFILLED(player));
    yield put(submitAction.FULLFILLED(data));

    /* Update player in all Player list */
    const index = allPlayers.findIndex(play => play.id === player.id);
    const players = copy(allPlayers);
    players[index] = player;
    yield put(getPlayersAction.FULLFILLED({ players: players, selectedShowcase }));
  } catch (error) {
    toast.error(error.details);
    yield put(submitAction.REJECTED());
  } finally {
    yield put(submitAction.REJECTED());
  }
}

function* updateBasicStats() {
  const selectedShowcase = yield select(state => state.showcase.selectedShowcase);
  const current_player = yield select(state => state.showcase.current_player);
  const allPlayers = yield select(state => state.showcase.players);
  try {
    const values = form(FORM.SHOWCASE).getValues();
    const formData = copy(formatData(values));
    const basicStats = form(FORM.BASIC_STATS).getValues();
    const payload = { data: { ...current_player, ...formData, ...basicStats } };
    const player = yield call(API.updatePlayer, payload);
    const paragraph = yield call(API.getParagraph, payload);
    const player_index = _.findIndex(allPlayers, { id: player.data.id });
    allPlayers[player_index].player_data.json_data = paragraph.player_data;
    allPlayers[player_index].player_data.paragraph = paragraph.player_data.paragraph;
    yield put(getPlayersAction.FULLFILLED({ players: allPlayers, selectedShowcase }));
    yield put(updateBasicStatsAction.FULLFILLED(allPlayers));
    yield put(setPlayerActions.STARTED(player.data.id));
    toast.success('Player updated successfully');
  } catch (error) {
    toast.error(error.details);
    yield put(updateBasicStatsAction.REJECTED());
  }
}

function* getDocument(action) {
  try {
    yield put(appLoadingAction.STARTED());
    const link = yield call(API.getDocument, action.payload);
    window.open(API_BASE_PATH + link.message);
    yield put(appLoadingAction.FULLFILLED());
  } catch (error) {
    yield put(appLoadingAction.REJECTED());
    toast.error(error.details);
  }
}

export function* watcherShowcase() {
  yield takeLatest(GET_STATES.STARTED, getStates);
  yield takeLatest(GET_PLAYERS.STARTED, getPlayers);
  yield takeLatest(SET_PLAYER.STARTED, setPlayer);
  yield takeLatest(UPDATE_BASIC_STATS.STARTED, updateBasicStats);
  yield takeLatest(SHOWCASE_PAYMENT.STARTED, showcasePayment);
  yield takeLatest(SUBMIT.STARTED, submit);
  yield takeLatest(GET_DOCUMENT, getDocument);
}

export const sagas = [watcherShowcase];
