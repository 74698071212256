import { statsTabs } from 'helpers/menuHelper'
export const initialState = {
    tabs: statsTabs,
    activeTab: {},
    states: [],
    players: [],
    current_player: '',
    allShowcases: {},
    selectedShowcase:{},
    showPaymentModal: false
}