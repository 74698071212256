const defaultJson = {
  position: 'Catcher',
  _fieldingVars: {
    Catcher: {
      hands: [],
      footwork: [],
      exc: []
    },
    Infield: {
      hands: [],
      footwork: [],
      exc: []
    },
    Outfield: {
      hands: [],
      footwork: [],
      exc: []
    }
  },
  _setup: null,
  _flexibility: null,
  _catcherOverall: null,
  _infieldOverall: null,
  _outfieldOverall: null,
  _lateralMovement: null,
  _fieldBall: null,
  _sixtyYard: null,
  _height: null,
  _weight: null,
  _extVelo: null,
  _hittingNotes: null,
  _fieldingThrowingNotes: null,
  _pitchingNotes: null,
  _catcherVelo: null,
  _popTime: null,
  _infieldVelo: null,
  _outfieldVelo: null,
  _fastBallVelo: null,
  _maxfBallVelo: null,
  _curveball: null,
  _changeup: null,
  _slider: null,
  _splitter: null,
  _cutter: null,
  _knuckle: null,
  _fork: null,
  _sixtyComesFirst: null,
  _hittingVelocityLast: null,
  _extPosVeloLast: null,
  paragraph: null,
  _bodyFrame: [],
  _strideType: null,
  _loadType: [],
  _stanceType: [],
  _barrels: null,
  _loudContact: null,
  _hittingOverall: null,
  _batSpeedType: null,
  _swingType: [],
  _finishType: null,
  _approach: null,
  _armType: null,
  _slotType: null,
  _accuracyType: null,
  _pitchDeliveryType: null,
  _effortType: null,
  _armAngleType: null,
  _armSpeedType: null,
  _armActionType: null,
  _balancePoint: null,
  _shoulders: null,
  _landing: null,
  _finish: null,
  _pitchingOverall: null,
  pitch_type: 'fastball',
  _pitchVars: {
    fastball: {
      act: null,
      acc: null,
      mov: null
    },
    curveball: {
      act: null,
      acc: null,
      mov: null
    },
    changeup: {
      act: null,
      acc: null,
      mov: null
    },
    slider: {
      act: null,
      acc: null,
      mov: null
    },
    splitter: {
      act: null,
      acc: null,
      mov: null
    },
    cutter: {
      act: null,
      acc: null,
      mov: null
    },
    knuckle: {
      act: null,
      acc: null,
      mov: null
    },
    fork: {
      act: null,
      acc: null,
      mov: null
    }
  }
};

export default defaultJson;
