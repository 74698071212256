import Store from './store';
import { batch } from './utils';
import { copy } from 'helpers/common';

export const setValues = (formName: string) => {
  return (values: any) => {
    const form: any = Store.getForm(formName);
    if (values && form) {
      batch(() => {
        Object.keys(values).forEach(key => {
          if (Array.isArray(values[key])) {
            const fieldArray = Store.getFieldArray(formName, key);
            if (fieldArray) {
              fieldArray.remove();
              setTimeout(() => {
                values[key].forEach(value => {
                  fieldArray.append(value);
                });
              }, 100);
            } else {
              form.setValue(key, copy(values[key]));
            }
          } else if(typeof values[key] === 'object' && values[key] !== null && values[key] !== undefined){
            Object.keys(values[key]).forEach(objKey => {
              if(typeof values[key][objKey] === 'object' && values[key][objKey] !== null && values[key][objKey] !== undefined){
                Object.keys(values[key][objKey]).forEach(objKey1 => {
                  form.setValue(`${key}.${objKey}.${objKey1}`, copy(values[key][objKey][objKey1]));
                })
              }else{
                form.setValue(`${key}.${objKey}`, copy(values[key][objKey]));
              }
            })
          }  else {
            form.setValue(key, copy(values[key]));
          }
        });
      });
    }
  };
};

export const setErrors = (formName: string) => {
  return (values: any) => {
    batch(() => {
      const form: any = Store.getForm(formName);
      if (values && form) {
        Object.keys(values).forEach(key => {
          let message = Array.isArray(values[key]) ? values[key][0] : values[key];
          if (typeof message === 'object' && message !== null) {
            message = message[key];
          }
          form.setError(key, {
            type: 'manual',
            message
          });
        });
      }
    });
  };
};
