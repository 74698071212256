import { initialState } from 'state/reducers/initialState';
import * as actionTypes from 'state/types';
import { IAction } from 'state/types';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.SET_STATS_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case actionTypes.GET_STATES.FULLFILLED:
      return {
        ...state,
        states: action.payload,
      };
    case actionTypes.GET_PLAYERS.FULLFILLED:
      return {
        ...state,
        players: action.payload.players,
        selectedShowcase: action.payload.selectedShowcase
      };
    case actionTypes.SHOWCASE_PAYMENT.FULLFILLED:
      return {
        ...state,
        selectedShowcase: action.payload
      };
    case actionTypes.SHOW_PAYMENT_MODAL:
      return {
        ...state,
        showPaymentModal: action.payload
      };
    case actionTypes.SET_PLAYER.FULLFILLED:
      return {
        ...state,
        current_player: action.payload,
      };
    case actionTypes.UPDATE_BASIC_STATS.FULLFILLED:
      return {
        ...state,
        current_player: action.payload,
      };
    case actionTypes.SET_SHOWCASES:
      return {
        ...state,
        allShowcases: action.payload,
      };
    default:
  }
  return state;
}

export default reducer;
