import each from 'lodash/each';
import replace from 'lodash/replace';
import { API_BASE_PATH } from 'config';

const ROUTES_OBJ = {
  login: `${API_BASE_PATH}/api/auth`,
  signup: `${API_BASE_PATH}/api/register`,
  getStates: `${API_BASE_PATH}/api/showcase/state`,
  getShowCase: `${API_BASE_PATH}/api/showcase/?page=<page>&q=<search>&future=<future>&state=<state>`,
  getPlayers: `${API_BASE_PATH}/api/player?showcase_id=<showcase_id>`,
  updatePlayer: `${API_BASE_PATH}/api/player`,
  getParagraph: `${API_BASE_PATH}/api/player/paragraph`,
  getDocument: `${API_BASE_PATH}/api/player/document`,
  showcasePayment: `${API_BASE_PATH}/api/payment/showcase`,
  addPlayer: `${API_BASE_PATH}/api/player`,
};

export type ROUTES = keyof typeof ROUTES_OBJ;
/**
 * getRoute creates the URL through provided routeName & params arguments
 * @param  {string} routeName   any object name of ROUTES_OBJ e.g. login
 * @param  {Object} [params={}] param values replace with strings present <...>.
 * @return {string}             URL
 */
const getRoute = (routeName: ROUTES, params = {}): string => {
  let url: string = ROUTES_OBJ[routeName];
  each(params, (val: string, key: string) => {
    val = Array.isArray(val) ? val.join(',') : val;
    url = replace(url, new RegExp(`<${key}>`, 'g'), val);
  });
  return url;
};

export default getRoute;
