/**
 * user.js is used to manage the user authorization cookies
 */
import Cookies from 'universal-cookie';
import axios from 'axios';
import { COOKIE_USER_TOKEN, COOKIE_USER } from 'config';

interface ILoginResponse {
  token: string;
  user: {
    email: string;
    id: number;
    is_active: boolean;
    payment: any;
  };
}

export function isLoggedIn(): boolean {
  const cookies = new Cookies();
  return cookies.get(COOKIE_USER_TOKEN);
}

export function deleteSession(): void {
  const cookies = new Cookies();
  cookies.remove(COOKIE_USER_TOKEN, { path: '/' });
  cookies.remove(COOKIE_USER, { path: '/' });
  delete axios.defaults.headers.common['Authorization']
}

export function saveUserSession(user: ILoginResponse) {
  const cookies = new Cookies();
  const session = {
    token: user.token
  };
  cookies.set(COOKIE_USER_TOKEN, session, { path: '/' });
  cookies.set(COOKIE_USER, { user: user.user}, { path: '/' });
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}


export function getSession(): ILoginResponse {
  const cookies = new Cookies();
  return cookies.get(COOKIE_USER_TOKEN);
}

export function getUser(): any {
  const cookies = new Cookies();
  return cookies.get(COOKIE_USER);
}

export function initSession() {
  const user = getSession();
  axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
}
