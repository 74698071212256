import { combineReducers } from 'redux';
import loader from 'state/reducers/loader';
import auth from 'pages/Auth/state/reducer';
import showcase from 'state/reducers/showcase';

export default combineReducers({
    loader,
    auth,
    showcase
});
