import { API_TYPE } from 'utils';

export interface IAction {
    type: string;
    payload?: any;
}

export const APP = API_TYPE('APP');
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const SUBMIT = API_TYPE('SUBMIT');

export const LOGIN = API_TYPE('LOGIN');
export const REGISTER = API_TYPE('REGISTER');

export const ADD_PLAYER = API_TYPE('ADD_PLAYER');

export const GET_STATES = API_TYPE('GET_STATES');
export const GET_PLAYERS = API_TYPE('GET_PLAYERS');
export const SET_PLAYER = API_TYPE('SET_PLAYER');
export const UPDATE_BASIC_STATS = API_TYPE('UPDATE_BASIC_STATS');
export const SHOWCASE_PAYMENT = API_TYPE('SHOWCASE_PAYMENT');

export const SET_STATS_TAB = 'SET_STATS_TAB';
export const SET_SHOWCASES = 'SET_SHOWCASES';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const SHOW_PAYMENT_MODAL = 'SHOW_PAYMENT_MODAL';
