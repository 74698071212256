/* eslint-disable no-constant-condition */
import { all } from 'redux-saga/effects';
import { watcherAuth } from 'pages/Auth/state/sagas';
import { watcherLoaders } from 'state/sagas/loader';
import { watcherShowcase } from 'state/sagas/showcase';
import { watcherPlayer } from 'state/sagas/player';

export default function* rootSaga() {
  const sagas = [
    watcherLoaders(),
    watcherAuth(),
    watcherShowcase(),
    watcherPlayer()
  ];
  yield all(sagas);
}
