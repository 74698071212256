import { LOGIN, REGISTER, RESET_PASSWORD, FORGOT_PASSWORD, LOGOUT } from 'pages/Auth/state/types';

export const loginAction = {
  STARTED: (email: string, password: string) => ({ type: LOGIN.STARTED, payload: { email, password } }),
  FULLFILLED: () => ({ type: LOGIN.FULLFILLED }),
  REJECTED: () => ({ type: LOGIN.REJECTED })
};

export const signupAction = {
  STARTED: (user: any) => ({ type: REGISTER.STARTED, payload: user })
};

export const forgotPasswordAction = {
  STARTED: (email: string) => ({ type: FORGOT_PASSWORD.STARTED, payload: { email } })
};

export const resetPasswordAction = {
  STARTED: (password: string, token: string) => ({ type: RESET_PASSWORD.STARTED, payload: { password, token } })
};

export const logoutAction =  () => ({ type: LOGOUT })
