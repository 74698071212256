import initialState from 'pages/Auth/state/initialState';
import { IAction } from 'store/types';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    default:
  }
  return state;
}

export default reducer;
