import { SET_STATS_TAB, GET_STATES, GET_PLAYERS, SET_PLAYER, SUBMIT, UPDATE_BASIC_STATS, SET_SHOWCASES, GET_DOCUMENT, SHOWCASE_PAYMENT, SHOW_PAYMENT_MODAL } from 'state/types';

export const getStatesAction = {
  STARTED: () => ({ type: GET_STATES.STARTED }),
  FULLFILLED: (results: any) => ({ type: GET_STATES.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: GET_STATES.REJECTED })
};

export const getPlayersAction = {
  STARTED: (showcase_id: string) => ({ type: GET_PLAYERS.STARTED, payload: showcase_id }),
  FULLFILLED: (results: any) => ({ type: GET_PLAYERS.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: GET_PLAYERS.REJECTED })
};

export const setPlayerActions = {
  STARTED: (player_id: string) => ({ type: SET_PLAYER.STARTED, payload: player_id }),
  FULLFILLED: (results: any) => ({ type: SET_PLAYER.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: SET_PLAYER.REJECTED })
};

export const submitAction = {
  STARTED: () => ({ type: SUBMIT.STARTED }),
  FULLFILLED: (results: any) => ({ type: SUBMIT.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: SUBMIT.REJECTED })
};

export const updateBasicStatsAction = {
  STARTED: () => ({ type: UPDATE_BASIC_STATS.STARTED }),
  FULLFILLED: (results: any) => ({ type: UPDATE_BASIC_STATS.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: UPDATE_BASIC_STATS.REJECTED })
};

export const showcasePaymentAction = {
  STARTED: (showcase_id: any, total_amount: any, paypal: any) => ({ type: SHOWCASE_PAYMENT.STARTED, payload: { showcase_id, total_amount, paypal } }),
  FULLFILLED: (results: any) => ({ type: SHOWCASE_PAYMENT.FULLFILLED, payload: results }),
  REJECTED: () => ({ type: SHOWCASE_PAYMENT.REJECTED })
};

export const setShowcasesAction = (showcases: any) => ({ type: SET_SHOWCASES, payload: showcases })

export const getDocumentAction = (showcase_id: any) => ({ type: GET_DOCUMENT, payload: showcase_id })

export const setStatsTabAction = (tab: any) => ({ type: SET_STATS_TAB, payload: tab })

export const setShowPaymentModal = (isShow: boolean) => ({ type: SHOW_PAYMENT_MODAL, payload: isShow })
