import React, { FC } from 'react';
import BlockUi from 'react-block-ui';
import { useSelector } from 'react-redux';
import 'react-block-ui/style.css';

interface ILoaderProps {
  children: any;
  type?: any;
  block?: boolean;
}

interface IReduxLoaderProps {
  children: any;
  type: any;
}

const getType = type => {
  return type.STARTED.replace('_STARTED', '');
};

const ReduxLoader: FC<IReduxLoaderProps> = ({ children, type }) => {
  let loadingState: string[] = [];

  type.forEach(t => {
    loadingState.push(getType(t));
  });
  const loader = useSelector((state: any) => state.loader);
  // const appLoading = useSelector((state: any) => state.loader.APP);
  const getLoading = () => {
    for (let loading of loadingState) {
      if (loader[loading]) return true;
    }
    return false;
  };
  return (
    <BlockUi tag="div" blocking={!!getLoading()}>
      {children}
    </BlockUi>
  );
};

const Loader: FC<ILoaderProps> = ({ children, type, block }) => (
  <>
    {type ? (
      <ReduxLoader type={type}>{children}</ReduxLoader>
    ) : (
      <BlockUi tag="div" blocking={block}>
        {children}
      </BlockUi>
    )}
  </>
);

export default Loader;
