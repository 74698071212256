import dJSON from 'dirty-json';
import get from 'lodash/get';

const ignoreConversion = [
  '_strideType',
  '_swingType',
  '_stanceType',
  '_loadType',
  '_bodyFrame',
  // '_barrels',
  // '_loudContact',
  '_hittingOverall',
  '_batSpeedType',
  '_finishType',
  '_approach',
  '_setup',
  '_catcherOverall',
  '_infieldOverall',
  '_outfieldOverall',
  '_lateralMovement',
  '_fieldBall',
  '_armType',
  '_accuracyType',
  '_pitchDeliveryType',
  '_armAngleType',
  '_armSpeedType',
  '_armActionType',
  '_balancePoint',
  '_shoulders',
  '_landing',
  '_finish',
  '_pitchingOverall',
  '_pitchVars.fastball.act'
];

export const copy = data => {
  return JSON.parse(JSON.stringify(data));
};

export const deFormatData = data => {
  let fielding = get(data, '_fieldingVars', null) ? dJSON.parse(data._fieldingVars) : [];
  fielding = fielding || [];
  const _fieldingVars = { Catcher: {}, Infield: {}, Outfield: {} };
  for (let i = 0; i < fielding.length; i++) {
    _fieldingVars[fielding[i].typFielding] = fielding[i];
    _fieldingVars[fielding[i].typFielding].hands = Array.isArray(fielding[i].hands) ? fielding[i].hands : [];
    _fieldingVars[fielding[i].typFielding].footwork = Array.isArray(fielding[i].footwork) ? fielding[i].footwork : [];
    _fieldingVars[fielding[i].typFielding].exc = Array.isArray(fielding[i].exc) ? fielding[i].exc : [];
  }
  data._fieldingVars = _fieldingVars;

  let pitch = get(data, '_pitchVars', null) ? dJSON.parse(data._pitchVars) : [];
  pitch = pitch || [];
  const _pitchVars = { fastball: {}, curveball: {}, changeup: {}, slider: {}, splitter: {}, cutter: {}, knuckle: {}, fork: {} };
  for (let i = 0; i < pitch.length; i++) {
    _pitchVars[pitch[i].typ] = pitch[i];
    _pitchVars[pitch[i].typ].acc = pitch[i].acc && pitch[i].acc !== 'None' ? pitch[i].acc : null;
    _pitchVars[pitch[i].typ].mov = pitch[i].mov && pitch[i].mov !== 'None' ? pitch[i].mov : null;
    _pitchVars[pitch[i].typ].act = pitch[i].act && pitch[i].act !== 'None' ? pitch[i].act : null;
  }

  // if not an array then convert to array
  // this method has been added to provide backward compatibility to already existing values which aren't in array format
  Object.keys(data).forEach(key => {
    const doConversion = ignoreConversion.includes(key);

    // let value = doConversion && get(data, key, null) ? dJSON.parse(data[key]) : [];
    if (ignoreConversion.includes(key)) {
      data[key] = get(data, key, null) ? dJSON.parse(data[key]) : [];
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]];
      }
    }
  });

  data._pitchVars = _pitchVars;
  // data._swingType = get(data, '_swingType', null) ? dJSON.parse(data._swingType) : [];
  // data._stanceType = get(data, '_stanceType', null) ? dJSON.parse(data._stanceType) : [];
  // data._loadType = get(data, '_loadType', null) ? dJSON.parse(data._loadType) : [];
  // data._bodyFrame = get(data, '_bodyFrame', null) ? dJSON.parse(data._bodyFrame) : [];
  data.paragraph = data.paragraph ? data.paragraph : get(data, 'player_data.paragraph', '');
  return data;
};

export const formatData = data => {
  let fielding = copy(data._fieldingVars);
  data._fieldingVars = [];
  Object.keys(fielding).forEach(key => {
    data._fieldingVars.push({
      typFielding: key,
      hands: fielding[key].hands?.length > 0 ? fielding[key].hands : null,
      footwork: fielding[key].footwork?.length > 0 ? fielding[key].footwork : null,
      exc: fielding[key].exc?.length > 0 ? fielding[key].exc : null
    });
  });
  let pitch = copy(data._pitchVars);
  data._pitchVars = [];
  Object.keys(pitch).forEach(key => {
    data._pitchVars.push({ typ: key, acc: pitch[key].acc, act: pitch[key].act, mov: pitch[key].mov });
  });

  delete data.pitch_type;
  delete data.valid_for;
  delete data.position;

  // data._loadType = data._loadType?.length > 0 ? data._loadType : null;
  // data._bodyFrame = data._bodyFrame?.length > 0 ? data._bodyFrame : null;
  // data._stanceType = data._stanceType?.length > 0 ? data._stanceType : null;
  // data._swingType = data._swingType?.length > 0 ? data._swingType : null;

  Object.keys(data).forEach(key => {
    if (data[key] === '') {
      data[key] = null;
    }

    const doConversion = ignoreConversion.includes(key);
    if (doConversion) {
      data[key] = data[key]?.length > 0 ? data[key] : null;
    }
  });
  return data;
};
