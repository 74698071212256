import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { ADD_PLAYER } from 'state/types';
import { addPlayerAction } from 'state/actions/player';
import history from 'utils/history';
import API from 'api';

function* addPlayer(action) {
  try {
    const player = yield call(API.addPlayer, action.payload);
    yield put(addPlayerAction.FULLFILLED(player));
    toast.success('Player added successfully');
    history.push('/showcase');
  } catch (error) {
    toast.error((Object.keys(error.data)[0] + ': ' + Object.values(error.data)[0]) as string);
    yield put(addPlayerAction.REJECTED());
  }
}

export function* watcherPlayer() {
  yield takeLatest(ADD_PLAYER.STARTED, addPlayer);
}

export const sagas = [watcherPlayer];
