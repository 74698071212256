const { REACT_APP_BASE_URL, REACT_APP_PER_PLAYER, REACT_APP_CLIENT_ID_PAYPAL } = process.env;

export const API_BASE_PATH: string = REACT_APP_BASE_URL || 'http://127.0.0.1:8000';
export const PER_PLAYER: string = REACT_APP_PER_PLAYER || '0.1';
export const CLIENT_ID_PAYPAL: string =
  REACT_APP_CLIENT_ID_PAYPAL || 'AZLaFnvNDiycOJfLgWfiGONQF1PL8Q_nFosVQ_len-h4RtzzEPq2VS3R2Gk4EHRBSexcGGrJhUOn8LxG';
export const COOKIE_USER_TOKEN = 'USER_SESSION';
export const TENANT_ID = 'TENANT_ID';
export const COOKIE_USER = 'COOKIE_USER';

/**
 * logout session if api response with status Code 401
 */

export enum FORM {
  LOGIN,
  REGISTER,
  SHOWCASE,
  BASIC_STATS,
  ADD_PLAYER
}
export const LOGOUT_ON_API_401 = true;
