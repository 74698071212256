import { useWatch } from "react-hook-form";

const useSharedWatch = (control: any) => {
  return function Watch(
    name: string,
    defaultValue: number | string | undefined | object
  ) {
    return useWatch({ control: control, name, defaultValue });
  };
};

export default useSharedWatch;
