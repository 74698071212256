import React, { useEffect, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import find from 'lodash/find';
import each from 'lodash/each';
import replace from 'lodash/replace';
import { Route, Switch, Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from 'components/common/Toast';
import { IPrivateRoute } from 'routes/types';
import PrivateRoute from 'routes/PrivateRoute';
import loadable from '@loadable/component';
import { clearLoadingAction } from 'state/actions/app';
import history from 'utils/history';
const AuthRoutes = loadable(() => import('pages/Auth'));
const HomeRoutes = loadable(() => import('pages/Home'));
const AboutUsRoutes = loadable(() => import('pages/AboutUs'));
const AddPlayerRoutes = loadable(() => import('pages/AddPlayer'));
const AgreementRoutes = loadable(() => import('pages/Agreement'));
const ShowcaseRoutes = loadable(() => import('pages/Showcase'));

const RoutesHOC = (routes: any, DEFAULT_PATH: any) => {
  return function Component() {
    const dispatch = useDispatch();

    history.listen((location, action) => {
      dispatch(clearLoadingAction());
    });

    useEffect(() => {
      AuthRoutes.preload();
    }, []);

    return (
      <Suspense fallback={<div />}>
        <Switch>
          {map(routes, route => {
            if (route.isPrivate) {
              return (
                <PrivateRoute
                  key={route.title}
                  title={route.title}
                  path={route.path}
                  component={route.component}
                  defaultPath={DEFAULT_PATH}
                />
              );
            }
            return <Route key={route.title} title={route.title} path={route.path} component={route.component} />;
          })}
          <Redirect to="/auth/login" />
        </Switch>
        <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.TOP_RIGHT} />
      </Suspense>
    );
  };
};

export const dashboardRoutes = {};

export const routes = {
  AUTH: {
    path: '/auth',
    title: 'Login',
    component: AuthRoutes,
    isPrivate: false
  },
  Home: {
    path: '/home',
    title: 'Home',
    component: HomeRoutes,
    isPrivate: true
  },
  AboutUs: {
    path: '/aboutus',
    title: 'AboutUs',
    component: AboutUsRoutes,
    isPrivate: false
  },
  Agreement: {
    path: '/agreement',
    title: 'Agrement',
    component: AgreementRoutes,
    isPrivate: false
  },
  AddPlayer: {
    path: '/addPlayer',
    title: 'AddPlayer',
    component: AddPlayerRoutes,
    isPrivate: true
  },
  Showcase: {
    path: '/showcase',
    title: 'Showcase',
    component: ShowcaseRoutes,
    isPrivate: true
  },
};

const AppRoutes = RoutesHOC(routes, '/login');
export default AppRoutes;

export const DEFAULT_PATH = 'auth/login';
export const USER_LANDING_PAGE = '/';

export const getTitleByPath = (path: string) => {
  const route = find(routes, (route: IPrivateRoute) => route.path === path);
  return route && route.title ? route.title : '';
};

export const createRoute = (url: string, params = {}): string => {
  each(params, (val: string, key: string) => {
    val = Array.isArray(val) ? val.join(',') : val;
    url = replace(url, new RegExp(`:${key}`, 'g'), val);
  });
  return url;
};

export const isLandingPage = (history: any) => {
  const { pathname } = history.location;
  if (pathname) {
    const path = find(dashboardRoutes, (route: any) => route.path === pathname);
    return path === USER_LANDING_PAGE || typeof path === 'undefined';
  }
  return true;
};
