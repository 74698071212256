import { call, takeLatest, put } from 'redux-saga/effects';
import { LOGIN, REGISTER, LOGOUT } from 'pages/Auth/state/types';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { saveUserSession, deleteSession } from 'utils/user';
import { loginAction } from 'pages/Auth/state/actions';
import API from 'api';

////////////// Handlers ///////////////////////
function* loginUser(action) {
  try {
    const payload = action.payload;
    const response = yield call(API.login, payload.email, payload.password);
    saveUserSession(response);
    yield put(loginAction.FULLFILLED());
    history.push('/home')
  } catch (error) {
    if (Array.isArray(error) && error.length > 0) {
      toast.error(error[0]);
    } else {
      toast.error(error.detail);
    }
    yield put(loginAction.REJECTED());
  }
}

function* signupUser(action) {
  try {
    const payload = action.payload;
    yield call(API.signup, payload);
    toast.success(`Successfully registered as ${payload.email}`);
    history.push(`/auth/login`);
  } catch (error) { 
    if (Array.isArray(error) && error.length > 0) {
      toast.error(error[0]);
    } else {
      toast.error(error.detail);
    }
  }
}

function logout() {
  deleteSession();
  history.push('/auth/login');
}

////////////// Watchers ///////////////////////
export function* watcherAuth() {
  yield takeLatest(LOGIN.STARTED, loginUser);
  yield takeLatest(REGISTER.STARTED, signupUser);
  yield takeLatest(LOGOUT, logout);
}

export const sagas = [watcherAuth];
